<template>
  <div>
    <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
    <form class="flex flex-col my-4 gap-2">
      <div class="flex flex-col relative input-container">
        <label class="p-2 label dark:text-stone-100" :class="{ 'active': copyYear.year }" for="email">Jaar</label>
        <input v-model="copyYear.year" @input="validateYear" :class="yearClass" class="dark:bg-stone-800 dark:text-stone-100 input-field focus:outline-none focus:shadow-outline shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 border-transparent focus:border-b-orange-600  border-t focus:border-t-orange-600 border-l focus:border-l-orange-600  border-r focus:border-r-orange-600" type="text" id="email"/>
        <p class="p-1 error-min-height text-red-500 text-xs italic">{{yearError}}</p>
      </div>
      <div class=" w-full ">
        <button class="w-full" type="submit" :disabled="isEdited === false || yearValid === false || !copyYear.year" @click.prevent="editYear" :class="buttonClass">Wijzigen</button>
      </div>
    </form>
  </div>
</template>

<script setup>
import {computed, inject, onMounted, reactive, ref} from "vue";
import {useStore} from "vuex";
import router from "@/router";
import {useRoute} from "vue-router";

const store = useStore();
const yearId = ref('')
const route = useRoute();
const year = reactive({
  id:'',
  year: ''
})

const copyYear = reactive({
  id:'',
  year: ''
})
const {yearService} = inject('data')
const yearValid = ref(null)
const yearError = ref('')
const createSuccessful = ref(null)



const back = () => {
  router.push("/profiel/admin-panel/jaren")
}

const getYearById = async () => {
  try {
    const {data} = await yearService.getYearById(yearId.value)
    Object.assign(year, { ...data });
    Object.assign(copyYear, {...year})

  } catch (error) {
    console.error(error)
  }
}

const editYear = async () => {
  try {
    const { message } =  await yearService.updateYear(copyYear)
    store.commit('setSuccessMessage', message)
    await router.push({path: '/profiel/admin-panel/jaren'})
    createSuccessful.value = true

  } catch (e) {
    createSuccessful.value = false
    yearValid.value = false
    store.commit('setErrorMessage', e.message)
  }
}



const validateYear = () => {
  const currentYear = new Date().getFullYear();
  const yearPattern = /^\d{4}$/;
  if (copyYear.year === "") {
    yearError.value = "Jaar mag niet leeg zijn.";
    yearValid.value = false;
  } else if (yearPattern.test(copyYear.year)) {
    const yearValue = parseInt(copyYear.year, 10);
    if (yearValue >= currentYear - 1 && yearValue <= currentYear + 1) {
      yearError.value = "";
      yearValid.value = true;
    } else {
      yearError.value = `Jaar moet tussen ${currentYear - 1} en ${currentYear + 1} liggen.`;
      yearValid.value = false;
    }
  } else {
    yearError.value = "Jaar moet een geldig jaartal zijn (vier cijfers).";
    yearValid.value = false;
  }
}

const isEdited = computed(() => {
  return (year.year !== copyYear.year)
});


const yearClass = computed(() => {
  if (createSuccessful.value === true) {
    return "border-b-green-600 focus:border-b-green-600 border-l-green-600 focus:border-l-green-600 border-r-green-600 focus:border-r-green-600 border-t-green-600 focus:border-t-green-600";
  } else if (yearValid.value === false) {
    return "border-b-red-600 focus:border-b-red-600 border-l-red-600 focus:border-l-red-600 border-r-red-600 focus:border-r-red-600 border-t-red-600 focus:border-t-red-600";
  } else {
    return "border-b-stone-200 dark:border-b-stone-600";
  }
})



const buttonClass = computed(() => {
  if (yearValid.value === false || !copyYear.year || !isEdited.value) {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 pb-2 pt-6 pr-4 pl-4 rounded border-b-4 button opacity-50 cursor-not-allowed";
  } else {
    return "dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button";
  }
});

onMounted(() => {
  yearId.value = route.params.id
  getYearById()
})

</script>