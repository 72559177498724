<template>
  <button @click.prevent="back" class="mb-2 dark:text-stone-100 dark:border-b-stone-600 p-2 shadow-own pb-2 pt-6 pr-4 pl-4 rounded border-b-4 hover:border-b-orange-600 button">Terug</button>
  <div class="flex gap-4 flex-col md:flex-row">
    <div class="flex flex-col gap-2 w-52 space-y-4">
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Email</h1>
        <p class="text-gray-600 dark:text-gray-400">{{user.email}}</p>
      </div>
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Voornaam</h1>
        <p class="text-gray-600 dark:text-gray-400">{{user.firstname}}</p>
      </div>
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Achternaam</h1>
        <p class="text-gray-600 dark:text-gray-400">{{user.lastname}}</p>
      </div>
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Telefoonnummer</h1>
        <p class="text-gray-600 dark:text-gray-400">{{user.phoneNumber}}</p>
      </div>
      <div v-if="isParent === false">
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Uurloon</h1>
        <p class="text-gray-600 dark:text-gray-400">€ {{user.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 })}}</p>
      </div>
      <div v-if="isParent === true">
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">Kind</h1>
        <p class="text-gray-600 dark:text-gray-400">{{child.firstname}} {{ child.lastname}}</p>
      </div>
      <div v-if="user.parentIds.length > 0">
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">{{ parentText }}</h1>
        <div v-for="parent in parents" :key="parent.id">
          <p class="text-gray-600 dark:text-gray-400">{{ parent.firstname }} {{ parent.lastname }}</p>
        </div>
      </div>
      <div>
        <h1 class="text-lg font-bold text-gray-800 dark:text-gray-200">{{roleText }}</h1>
        <div v-for="role in user.roles" :key="role.id">
          <p class="text-gray-600 dark:text-gray-400">{{role.frontendName}}</p>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <img class="shadow-own object-cover aspect-square rounded-lg " :src="`https://www.rickveerman.nl/${user?.profilePicture}`" alt="">
    </div>

  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {computed, inject, onMounted, reactive, ref} from "vue";
const router = useRouter();
const roles = reactive({list: []})
const route = useRoute();
const userId = ref(route.params.id)
const isParent = ref(false)
const {userService, roleService} = inject('data')
const user = reactive( {
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
  roles: [
  ],
  childId: '',
  parentIds: []
})

const child = reactive( {
  id: '',
  firstname: '',
  lastname: ''
})

const parents = reactive([])

async function getChildById() {
  const {data} = await userService.getUserById(user.childId)
  Object.assign(child, { ...data });
}

async function getParentById(parentId) {
  const {data} = await userService.getUserById(parentId)
  parents.push(data)
}

const getUserById = async () => {
  try {
    const {data} = await userService.getUserById(userId.value)
    Object.assign(user, { ...data });
    console.log(user)
    if (user.parentIds.length > 0) {
      for (let i = 0; i < user.parentIds.length; i++) {
        await getParentById(user.parentIds[i])
      }
    }
    if (user.childId !== '') {
      await getChildById()
    }
    data.roles.forEach(role => {
      if (role.frontendName === 'Ouder') {
        isParent.value = true;
      }
    });


    console.log(user.parentIds.length > 0)
    await getRoles()
  } catch (error) {
    console.error(error)
  }
}


const back = () => {
  router.push("/profiel/admin-panel/gebruikers")
}

const getRoles = async () => {
  try {
    const { data } = await roleService.getAllRoles()
    roles.list = data
        .filter(r => !user.roles.some(ur => ur.id === r.id))
        .map(r => ({...r}))
  } catch (e) {
    console.error(e)
  }
}


const roleText = computed(() => {
  if (user.roles.length > 1) {
    return "Rollen"
  } else {
    return "Rol"
  }
})

const parentText = computed(() => {
  if (user.parentIds.length > 1) {
    return "Ouders"
  } else {
    return "Ouder"
  }
})

onMounted(() => {
  getUserById()
})

</script>