// Define the UserDto class
export class User {
    constructor(id, email, firstname, lastname, phoneNumber, hourlyWage, profilePicture, permanentContract, settings, roles, childId, parentIds) {
        this.id = id;
        this.email = email;
        this.firstname = firstname;
        this.lastname = lastname;
        this.phoneNumber = phoneNumber;
        this.hourlyWage = hourlyWage;
        this.profilePicture = profilePicture;
        this.permanentContract = permanentContract
        this.settings = settings
        this.roles = roles;
        this.childId = childId
        this.parentIds = parentIds
    }
}


