import CustomError from "../../CustomError";



export class UserService {
    url
    constructor(url) {
        this.url = url;
    }

    async fetchJson(url, options) {
        try {
            const response = await fetch(url, options);

            if (response.ok) {
                return await response.json();
            }
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getUserByToken() {
        try {
            return await this.fetchJson(`${this.url}/token`);
        } catch (error) {
            if (error.message === "Cannot read properties of undefined (reading 'push')") {
                error.status = 401
            }
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }




    async getUserById(id) {
        try {

            return await this.fetchJson(`${this.url}/${id}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async getUserSettingsById(id) {
        try {

            return await this.fetchJson(`${this.url}/settings/${id}`);
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async updateUserSettings(userSettings, id) {
        try {

            return await this.fetchJson(`${this.url}/settings/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(userSettings),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }
    async createUser(user) {
        try {
            return await this.fetchJson(`${this.url}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async addRole(user, roleId) {
        try {
            return await this.fetchJson(`${this.url}/${user.id}/role/add/${roleId}`, {
                method: 'PUT',
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }


    async removeRole(user, roleId) {
        try {
            return await this.fetchJson(`${this.url}/${user.id}/role/remove/${roleId}`, {
                method: 'PUT',
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }




    async updateUser(user) {
        try {
            return await this.fetchJson(`${this.url}/${user.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user),
            });
        } catch (error) {
            const customError = new CustomError(error.message, error.timestamp, error.details, error.status)
            throw customError.toJSON()
        }
    }

    async updateProfilePicture(user) {
        try {
            return await this.fetchJson(`${this.url}/${user.id}/profile-picture`, {
                method: 'PUT',
                body: user.profilePictureFile
            })
        } catch (e) {
            const customError = new CustomError(e.message, e.timestamp, e.details, e.status)
            throw customError.toJSON()
        }
    }

    async updatePassword(user) {
        try {
            return await this.fetchJson(`${this.url}/${user.id}/update-password`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(user),
            })
        } catch (e) {
            const customError = new CustomError(e.message, e.timestamp, e.details, e.status)
            throw customError.toJSON()
        }
    }

    async getAllUsers() {
        try {
            return await this.fetchJson(`${this.url}`)
        } catch (e) {
            const customError = new CustomError(e.message, e.timestamp, e.details, e.status)
            throw customError.toJSON()
        }
    }

    async removeUser(userId) {
        try {
            return await this.fetchJson(`${this.url}/${userId}`, {
                method: 'DELETE'
            })
        } catch (e) {
            const customError = new CustomError(e.message, e.timestamp, e.details, e.status)
            throw customError.toJSON()
        }
    }

}

