<template>
  <div class="w-full">
    <table class="table-auto dark:text-stone-100 shadow-own">
      <thead class="border-black dark:border-white border-2 ">
      <tr>
        <th class="p-1 md:p-2 break-words whitespace-normal text-left">Jaar</th>
        <th class="p-1 md:p-2 text-left">
          <button @click.prevent="createYear" class="rounded h-7 w-7 p-2 bg-green-500 shadow-own hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-900 duration-200">
            <img class="h-full w-full" src="../../../../assets/plus-white.png" alt="Plus"/>
          </button>
        </th>
      </tr>
      </thead>
      <tbody class="border-black dark:border-white border-2">
      <tr v-for="year in years.list" :key="year.id">
        <td class="p-1  md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border break-words whitespace-normal">{{ year.year }}</td>
        <td class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">
          <div class="flex gap-1">
            <button @click.prevent="getYear(year.id)" class="rounded h-7 w-7 p-2 bg-black dark:bg-stone-100 shadow-own hover:bg-stone-700 dark:hover:bg-stone-300 duration-200">
              <img class="h-full w-full" :src="isDark ? require(`../../../../assets/info-black.png`) : require('../../../../assets/info-white.png')" alt="Info"/>
            </button>
            <button @click.prevent="editYear(year.id)" class="rounded h-7 w-7 p-2 bg-yellow-500 shadow-own hover:bg-yellow-700 dark:bg-yellow-700 dark:hover:bg-yellow-900 duration-200">
              <img  class="h-full w-full" src="../../../../assets/edit-white.png" alt="Bin"/>
            </button>
            <button @click.prevent="deleteYear(year.id)" class="rounded h-7 w-7 p-2 bg-red-500 shadow-own hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-900 duration-200">
              <img class="h-full w-full" src="../../../../assets/bin.png" alt="Bin"/>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive} from "vue";
import {useDark} from "@vueuse/core";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
const router = useRouter();

const {yearService} = inject('data')
const years = reactive({list: []})
const isDark = useDark()
const store = useStore();


const deleteYear = async (yearId) => {
  try {
    const { message } = await yearService.removeYear(yearId)
    store.commit('setSuccessMessage', message)
    await getAllYears()
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}


const getYear = async (id) => {
  await router.push(`/profiel/admin-panel/jaar/${id}`)
}

const createYear = async () => {
  await router.push(`/profiel/admin-panel/jaar/aanmaken`)
}

const editYear = async (id) => {
  await router.push(`/profiel/admin-panel/jaar/wijzigen/${id}`)
}

const getAllYears = async () => {
  try {
    const { data } = await yearService.getAllYears();
    // Filter out the logged-in user from the list of all users
    years.list = data.map(u => ({...u}));
  } catch (e) {
    console.error(e);
  }
};

onMounted(() => {
  getAllYears()
})


</script>

