<template>
  <div class="w-full">
    <table class="table-auto dark:text-stone-100 shadow-own">
      <thead class="border-black dark:border-white border-2 ">
        <tr>
          <th class="p-2 text-left">Email</th>
          <th class="hidden xl:table-cell p-2 text-left">Voornaam</th>
          <th class="hidden 2xl:table-cell p-2 text-left">Achternaam</th>
          <th class="hidden 3xl:table-cell p-2 text-left">Telefoonnummer</th>
          <th class="hidden 3xl:table-cell p-2 text-left">Uurloon/Kind</th>
          <th class="p-1 md:p-2 text-left">
            <button @click.prevent="createUser" class="rounded h-7 w-7 p-2 bg-green-500 shadow-own hover:bg-green-700 dark:bg-green-700 dark:hover:bg-green-900 duration-200">
              <img class="h-full w-full" src="../../../../assets/plus-white.png" alt="Plus"/>
            </button>
          </th>
        </tr>
      </thead>
      <tbody class="border-black dark:border-white border-2">
        <tr v-for="user in users.list" :key="user.id">
          <td class="p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">{{ user.email }}</td>
          <td class="hidden xl:table-cell p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">{{ user.firstname}}</td>
          <td class="hidden 2xl:table-cell p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">{{ user.lastname}}</td>
          <td class="hidden 3xl:table-cell p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">{{ user.phoneNumber}}</td>
          <td v-if="user.child === null" class="hidden 3xl:table-cell p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">€ {{ user.hourlyWageFormatted}}</td>
          <td v-if="user.child !== null" class="hidden 3xl:table-cell p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">{{ user.child.firstname}} {{ user.child.lastname}}</td>
          <td class="p-1 md:p-2 text-xs border-b-black dark:border-b-white border-l-0 border-r-0 border">
            <div class="flex gap-1">
              <button @click.prevent="getUser(user.id)" class="rounded h-7 w-7 p-2 bg-black dark:bg-stone-100 shadow-own hover:bg-stone-700 dark:hover:bg-stone-300 duration-200">
                <img class="h-full w-full" :src="isDark ? require(`../../../../assets/info-black.png`) : require('../../../../assets/info-white.png')" alt="Info"/>
              </button>
              <button @click.prevent="editUser(user.id)" class="rounded h-7 w-7 p-2 bg-yellow-500 shadow-own hover:bg-yellow-700 dark:bg-yellow-700 dark:hover:bg-yellow-900 duration-200">
                <img  class="h-full w-full" src="../../../../assets/edit-white.png" alt="Bin"/>
              </button>
              <button @click.prevent="deleteUser(user.id)" class="rounded h-7 w-7 p-2 bg-red-500 shadow-own hover:bg-red-700 dark:bg-red-700 dark:hover:bg-red-900 duration-200">
                <img class="h-full w-full" src="../../../../assets/bin.png" alt="Bin"/>
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import {inject, onMounted, reactive} from "vue";
import {useDark} from "@vueuse/core";
import {useRouter} from "vue-router";
import {useStore} from "vuex";
const router = useRouter();

const {userService, sessionSBService} = inject('data')
const users = reactive({list: []})
const token = sessionSBService.getTokenFromBrowserStorage()
const isDark = useDark()
const store = useStore();

const loggedInUser = reactive({
  id: '',
  email: '',
  firstname: '',
  lastname: '',
  phoneNumber: '',
  hourlyWage: '',
});


const getUserByToken = async () => {
  try {
    const {data} = await userService.getUserByToken(token)
    Object.assign(loggedInUser, { ...data });
    loggedInUser.hourlyWage = loggedInUser.hourlyWage.toLocaleString('nl-NL', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  } catch (error) {
    if (error.status === 401) {
      sessionSBService.deleteTokenFromBrowserStorage();
      await router.push({path: '/inloggen'})
    }
    console.error(error)
  }
}

const deleteUser = async (userId) => {
  try {
    const { message } = await userService.removeUser(userId)
    store.commit('setSuccessMessage', message)
    await getAllUsers()
  } catch (e) {
    store.commit('setErrorMessage', e.message)
  }
}


const getUser = async (id) => {
  await router.push(`/profiel/admin-panel/gebruiker/${id}`)
}

const createUser = async () => {
  await router.push(`/profiel/admin-panel/gebruiker/aanmaken`)
}

const editUser = async (id) => {
  await router.push(`/profiel/admin-panel/gebruiker/wijzigen/${id}`)
}

const getAllUsers = async () => {
  try {
    const { data } = await userService.getAllUsers();

    // Map through users to fetch child data if childId exists, and wait for all promises to resolve
    users.list = await Promise.all(
        data
            .filter(user => user.id !== loggedInUser.id)
            .map(async u => {
              const childData = u.childId ? await getChildById(u.childId) : null;
              return {
                ...u,
                hourlyWageFormatted: u.hourlyWage.toLocaleString('nl-NL', { minimumFractionDigits: 2 }),
                child: childData,
              };
            })
    );
    console.log(users.list)
  } catch (e) {
    console.error(e);
  }
};


async function getChildById(id) {
  const {data} = await userService.getUserById(id)
  return data
}


onMounted(() => {
  getAllUsers()
  getUserByToken()
})


</script>

